import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react'
import React, { ChangeEvent, Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, withRouter } from 'react-router'
import { Capacitor } from '@capacitor/core'
import routes from '../../routes'
import Request from './Start.request'
import { actions, selectors, StartState, StartActions } from './Start.state'
import Page from '../../components/Page/Page'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import AppStorage from '../../components/App/App.storage'
import firebaseApp from '../../config/firebase.config'

interface StartProps extends StartState, StartActions, RouteComponentProps {}

interface State {
  loading: boolean,
  authenticated: boolean,
  name: string
}

const env = process.env.REACT_APP_ENV || 'development'
const config = require('../../config/config.json')[env]
const urlParams = new URLSearchParams(window.location.search)

class Start extends Component<StartProps, State> {
  request: Request
  appStorage: AppStorage

  constructor (props: any) {
    super(props)
    this.request = new Request()
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleStartClick = this.handleStartClick.bind(this)

    this.appStorage = new AppStorage()
    let loungeName = ''
    this.appStorage.getUser((user) => {
      if (user) {
        loungeName = `${user!.first_name}'s Lounge`
      }
    })

    this.state = {
      loading: true,
      authenticated: false,
      name: loungeName
    }
  }

  componentWillMount () {
    this.checkAuth().then(() => {
      console.log('Authenticated, updating state...')
      this.setState({
        loading: false,
        authenticated: true
      })
      if (urlParams.get('token')) {
        urlParams.delete('token')
        this.props.history.replace({
          pathname: window.location.pathname,
          search: urlParams.toString()
        })
      }
    }).catch(() => {
      this.setState({
        loading: false,
        authenticated: false
      })
    })
  }

  checkAuth = async () => {
    return new Promise((resolve, reject) => {
      this.appStorage.getUser(async (user) => {
      // console.log(window.location.search);
        // console.log(JSON.stringify(auth));
        console.log('Checking for user token...')

        if (user) {
          this.setState({ name: `${user.first_name}'s Lounge` })
          resolve(true)
        }

        if (!user) {
        // TODO Should be able to phase this out or create a central helper to initiate it
          console.log('User is not authenticated, testing for credentials...')
          const userToken = urlParams.get('token')
          axios.defaults.headers.common.Authorization = `Bearer ${userToken}`
          if (userToken) {
            console.log('Got user token, authenticating...')
            const response = await axios.get(`${config.apiEndPoint}/users/credentials`)
            if (response.data && response.data.tokenId) {
              console.log(JSON.stringify(console.log(response.data)))
              console.log('Got custom token:')
              console.log(response.data.tokenId)
              const updated = await firebaseApp.auth().signInWithCustomToken(response.data.tokenId)
              if (updated) {
                console.log('Dispatching authUser...')
                const tokenId = await firebaseApp.auth().currentUser?.getIdToken()
                if (tokenId) {
                  this.setState({ name: `${response.data.user.first_name}'s Lounge` })
                  await this.appStorage.setUser(response.data.user, async () => {
                    await this.appStorage.setAccessToken(tokenId, () => {
                      console.log('User SSO successful')
                      // window.location.replace(window.location.href.split('?')[0]);
                      resolve(true)
                    })
                  })
                }
              } else {
                console.log('Unable to sign in with custom token')
                // Sentry.captureException(new Error('Unable to sign in with custom token'));
                // FIXME Better alert dialog here
                alert('There was a problem with authentication. We are working on a fix, but if the problem persists, please contact support.')
              }
            } else {
              console.log('Unable to get user credentials from server')
              // Sentry.captureException(new Error('Unable to get user credentials from server'));
              // FIXME Better alert dialog here
              alert('There was a problem reaching our servers. We are working on a fix, but if the problem persists, please contact support.')
            }
          } else {
            console.log('No auth token to use, redirecting...')
            reject()
          }
        }
      })
    })
  };

  redirect = () => {
    console.log('Redirecting to auth...')
    const authUrl = `${config.authEndPoint}/login?path=${window.location.href.split('?')[0]}`
    // When wrapped in Capacitor for Android / iOS native apps, see https://capacitor.ionicframework.com/docs/apis/browser
    if (Capacitor.isNative) {
      // FIXME This isn't running in iOS simulator - not sure if it will on a real device. EDIT: Can't tell if it does
      //  on an Android device, but doesn't seem like it does - also too slow to load the page (white screen for a while)
      // TODO Use something like https://github.com/TeamHive/capacitor-single-sign-on ??
      console.log('Native device, opening browser with Capacitor')
      // Browser.open({ url: authUrl });
    } else {
      window.location.replace(authUrl)
    }
  };

  handleInputChange (event: ChangeEvent) {
    const target = event.target as HTMLInputElement
    const { value, name } = target
    switch (name) {
      case 'username':
        this.props.replaceUsername(value)
        break
      case 'password':
        this.props.replacePassword(value)
        break
    }
  }

  // FIXME When the user gets to this point, they will already be logged in - this button should create a new lounge
  handleStartClick () {
    const { name } = this.state
    this.request.create(name, () => {
      this.props.replaceAppAuthType('host') // FIXME This prob isn't necessary since all auth is "host" now - remove Admin code
      this.props.history.push(routes.meeting.path)
    })
  }

  clearForm () {
    // FIXME No longer needed
    this.props.replaceUsername('')
    this.props.replacePassword('')
  }

  componentWillUnmount () {
    this.clearForm()
  }

  renderLoading = () => (
    <IonPage>
      <IonContent>
        <Page
          invert={false}
          container="small">
          <div className="loader-wrapper">
            <div className="loader">
              <div style={{ textAlign: 'center' }}>
                <IonSpinner name="dots" color="light" />
                <h4 style={{ color: '#efefef' }}>'Bout that time... <span><img src="https://cdn.boxpressd.io/placeholder/boxpressd-liked.png" /></span></h4>
              </div>
            </div>
          </div>
        </Page>
      </IonContent>
    </IonPage>
  );

  render () {
    if (this.state.loading) {
      return this.renderLoading()
    }
    if (!this.state.loading && !this.state.authenticated) {
      return (
        <Route path='/start' component={() => {
          this.redirect()
          return null
        }}/>
      )
    }
    return (
      <IonPage>
        <IonContent>
          <Page
            invert={false}
            container="small">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <span style={{ color: '#efefef' }}>Enter Lounge Name</span>
                  <Input
                    name="lounge"
                    type="text"
                    value={this.state.name}
                    placeholder="Lounge Name"
                    handleChange={this.handleInputChange} />
                  <Button
                    text="Create Lounge"
                    handleClick={this.handleStartClick} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </Page>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(connect(selectors, actions)(Start))
