import {
  IonAvatar,
  IonChip,
  IonContent,
  IonGrid, IonIcon,
  IonItem,
  IonLabel, IonList,
  IonPage,
  IonSearchbar, IonToolbar,
  isPlatform
} from '@ionic/react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { every, filter, find, forEach, includes } from 'lodash'
import { MediaConnection } from 'peerjs'
import { IEvent } from 'fabric/fabric-impl'
import { isMobile } from 'mobile-device-detect'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select/async'
import routes from '../../routes'
import PeerService from '../../services/PeerService'
import MediaService from '../../services/MediaService'
import MeetingService from '../../services/MeetingService'
import VideoService from '../../services/VideoService'
import AuthService from '../../services/AuthService'
import AlertService from '../../services/AlertService'
import NotificationService from '../../services/NotificationService'
import WhiteboardDrawingService from '../../services/Whiteboard/WhiteboardDrawingService'
import WhiteboardCanvasService from '../../services/Whiteboard/WhiteboardCanvasService'
import ScreenCaptureService from '../../services/ScreenCaptureService'
import RTPSenderService from '../../services/RTPSenderService'
import StreamTrackService from '../../services/StreamTrackService'
import AppStorage from '../../components/App/App.storage'
import HostMiddleware from '../../middleware/HostMiddleware'
import MeetingRepository from '../../repositories/MeetingRepository'
import socket from './Meeting.socket'
import { selectors, actions, MeetingState, MeetingActions, VideoState, MeetingWhiteboardDrawingState } from './Meeting.state'
import Page from '../../components/Page/Page'
import Menu, { MenuPosition } from '../../components/Menu/Menu'
import ActiveVideo from '../../components/ActiveVideo/ActiveVideo'
import VideoBlock from '../../components/VideoBlock/VideoBlock'
// import ActiveVideoSkeleton from '../../components/ActiveVideoSkeleton/ActiveVideoSkeleton'
import styles from './Meeting.module.scss'
import SmokeSessionModal from '../../components/SmokeSessionModal/SmokeSessionModal'
import VideoSkeleton from '../../components/VideoSkeleton/VideoSkeleton'
import Actions from '../../components/Actions/Actions'
import { closeOutline } from 'ionicons/icons'
import Button from '../../components/Button/Button'

const env = process.env.REACT_APP_ENV || 'development'
const config = require('../../config/config.json')[env]

interface MeetingProps extends MeetingState, MeetingActions, RouteComponentProps {}

interface State {
  hasFocus?: boolean;
  searchResults?: Array<any>;
  selectedCigar?: any,
  showSmokeSessionModal: boolean,
  shareUrl: string
}

class Meeting extends Component<MeetingProps, State> {
  static raiseHandTimeout: number = 5000
  static videoColumnSize: string = isPlatform('mobile') ? '4' : '2'
  peerService: PeerService
  mediaService: MediaService
  meetingService: MeetingService
  videoService: VideoService
  authService: AuthService
  notificationService: NotificationService
  whiteboardDrawingService: WhiteboardDrawingService
  whiteboardCanvasService: WhiteboardCanvasService
  screenCaptureService: ScreenCaptureService
  rtpSenderService: RTPSenderService
  streamTrackService: StreamTrackService
  appStorage: AppStorage
  hostMiddleware: HostMiddleware
  meetingRepository: MeetingRepository

  createLink = (service: string) => {
    const url = `https://${config.loungeUrl}`
    const route = `${url}/join?id=${this.props.id}`
    console.log('Setting route from VideoSkeleton: ', route)
    return new Promise((resolve, reject) => {
      const linkData = {
        channel: service,
        data: {
          path: 'lounge',
          route,
          $og_title: 'Boxpressd Virtual Lounge',
          $og_description: 'Join a Virtual Cigar Lounge on Boxpressd'
          // $og_image_url: data.image, // TODO
          // $og_video: data.video
        }
      }
      window.branch.link(linkData, (err, link) => {
        if (!err) {
          console.log('Created Deep Link:')
          console.log(link)
          resolve(link)
        } else {
          console.log(err)
          reject(err)
        }
      })
    })
  };

  constructor (props: MeetingProps) {
    super(props)
    this.peerService = new PeerService()
    this.mediaService = new MediaService()
    this.meetingService = new MeetingService()
    this.videoService = new VideoService()
    this.authService = new AuthService()
    this.notificationService = new NotificationService()
    this.whiteboardDrawingService = new WhiteboardDrawingService()
    this.whiteboardCanvasService = new WhiteboardCanvasService()
    this.screenCaptureService = new ScreenCaptureService()
    this.rtpSenderService = new RTPSenderService()
    this.streamTrackService = new StreamTrackService()
    this.appStorage = new AppStorage()
    this.hostMiddleware = new HostMiddleware()
    this.hostMiddleware.auth(this.props.history)
    this.meetingRepository = new MeetingRepository()
    this.handleMenuRaiseHandClick = this.handleMenuRaiseHandClick.bind(this)
    this.handleMenuEndMeetingClick = this.handleMenuEndMeetingClick.bind(this)
    this.handleMenuVideoClick = this.handleMenuVideoClick.bind(this)
    this.handleMenuAudioClick = this.handleMenuAudioClick.bind(this)
    this.handleMenuScreenShareClick = this.handleMenuScreenShareClick.bind(this)
    this.handleMenuWhiteboardClick = this.handleMenuWhiteboardClick.bind(this)
    this.handleMenuMemberRemoveClick = this.handleMenuMemberRemoveClick.bind(this)
    this.handleMenuToggleChange = this.handleMenuToggleChange.bind(this)
    this.handleVideoBlockClick = this.handleVideoBlockClick.bind(this)
    this.handleWhiteboardCanvasClearClick = this.handleWhiteboardCanvasClearClick.bind(this)
    this.handleWhiteboardDrawingAdd = this.handleWhiteboardDrawingAdd.bind(this)

    this.state = {
      hasFocus: false,
      searchResults: [],
      selectedCigar: null,
      showSmokeSessionModal: false,
      shareUrl: '',
    }
  }

  private updateLink (): void {
    setTimeout(() => {
      const meetingId = this.props.id
      // console.log('Meeting ID: ', meetingId)
      if (meetingId && meetingId.trim().length > 0) {
        this.createLink('lounge').then((link) => {
          // console.log('Got share link: ', link)
          // @ts-ignore
          this.setState({ shareUrl: link })
        })
      } else {
        this.updateLink()
      }
    }, 250)
  }

  isMenuExpanded (position: MenuPosition) {
    const { menuExpanded } = this.props
    return includes(menuExpanded, position)
  }

  get canMenuTopSpace () {
    return (this.activeVideo && this.isMenuExpanded('top'))
  }

  get className () {
    const mobileClass = isPlatform('mobile') && styles.mobile
    const menuTopSpaceClass = this.canMenuTopSpace && styles.menuTopSpace
    return `${styles.meeting} ${mobileClass} ${menuTopSpaceClass}`
  }

  get activeVideo () {
    // console.log('All videos:')
    // console.log(this.props.videos)
    return find(this.props.videos, 'active')
  }

  get activeVideoSkeletonVisibility () {
    return !this.activeVideo
  }

  // FIXME This is always the hosts video feed, but should be the current user's
  get activeVideoBlock () {
    return this.activeVideo && this.props.videos.length < 3 && (
      <ActiveVideo
        video={this.activeVideo}
        whiteboardDrawings={this.props.whiteboardDrawings}
        whiteboardEnabled={this.props.whiteboardEnabled}
        handleWhiteboardCanvasClearClick={this.handleWhiteboardCanvasClearClick}
        handleWhiteboardDrawingAdd={this.handleWhiteboardDrawingAdd}
        handleVideoBlockClick={this.handleVideoBlockClick} />
    )
  }

  get userVideos () {
    console.debug('User videos:')
    console.debug(this.props.videos)
    if (this.props.videos.length < 3) {
      return filter(this.props.videos, { active: false })
    }
    return this.props.videos
  }

  get videos () {
    const videos = this.userVideos
    return videos.map(video => this.video(video))
  }

  getCigars = (inputValue: string, callback: any) => {
    axios.get(`https://api.boxpressd.io/cigars/search?q=${inputValue}&page=1&limit=50`).then((response) => {
      // TODO Include the vitola in the choices?
      this.setState({ searchResults: response.data })
      callback && callback(response.data.map((cigar: any) => ({ label: cigar.full_name, value: cigar.id })))
    })
  };

  // eslint-disable-next-line camelcase
  getCigarImage (cigar: { images: Array<{ image_type: number, image_url: string }> }) {
    if (cigar.images && cigar.images.length) {
      for (let i = 0; i < cigar.images.length; i++) {
        const image = cigar.images[i]
        // if (image.image_type === 6) { // 'Display' image type
        if (image.image_type === 0) { // 'Band' image type
          return image.image_url
        }
      }
    }
    return 'https://cdn.boxpressd.io/placeholder/100x100/no_cigar.png'
  };

  get userVideo () {
    const { connectionId, videos } = this.props
    return find(videos, { id: connectionId })
  }

  get canInviteMember () {
    return Boolean(this.props.id)
  }

  get canRaiseHand () {
    if (!this.userVideo) return false
    return !this.userVideo.raiseHand
  }

  get canEndMeeting () {
    return Boolean(this.props.id)
  }

  get canVideoMute () {
    if (!this.userVideo) return false
    return !this.userVideo.streamMuted.video
  }

  get canAudioMute () {
    if (!this.userVideo) return false
    return !this.userVideo.streamMuted.audio
  }

  get canScreenShare () {
    const videos = this.props.videos
    return every(videos, video => (video.kind !== 'screen'))
  }

  get canWhiteboardEnable () {
    return !this.props.whiteboardEnabled
  }

  get inviteText () {
    const url = `https://${process.env.REACT_APP_HOST}`
    const { id } = this.props
    return `Hi there,\nYou can join a Virtual Lounge from ${url}/join?id=${id}`
  }

  handleStreamStop () {
    const screenStream = this.meetingService.getScreenStream()
    const tracks = screenStream.getTracks()
    forEach(tracks, track => {
      track.onended = () => this.switchUserStreamToMedia()
    })
  }

  switchUserStreamToMedia () {
    this.mediaService.getStream(this.props.history, () => {
      const mediaStream = this.meetingService.getMediaStream()
      this.videoService.replaceUserStream(mediaStream, 'media')
      this.rtpSenderService.replaceStream(mediaStream)
      const screenStream = this.meetingService.getScreenStream()
      this.streamTrackService.stop(screenStream)
    })
  }

  switchUserStreamToScreen () {
    this.screenCaptureService.getStream(() => {
      const screenStream = this.meetingService.getScreenStream()
      this.videoService.replaceUserStream(screenStream, 'screen')
      this.rtpSenderService.replaceStream(screenStream)
      this.handleStreamStop()
      const mediaStream = this.meetingService.getMediaStream()
      this.streamTrackService.stop(mediaStream)
    })
  }

  handleMenuRaiseHandClick () {
    if (this.userVideo?.raiseHand) return
    const { id, connectionId } = this.props
    socket.raiseHand.publish(id, connectionId)
  }

  handleMenuEndMeetingClick () {
    const prompt = 'Are you sure to leave the lounge?'
    AlertService.push(prompt, () => {
      this.peerService.disconnect()
    })
  }

  handleMenuVideoClick () {
    if (this.userVideo) {
      const enabled = this.mediaService.toggleVideoMute(this.userVideo.stream)
      this.props.replaceVideoStreamMutedVideo(this.props.connectionId, !enabled)
    }
  }

  handleMenuAudioClick () {
    if (this.userVideo) {
      const enabled = this.mediaService.toggleAudioMute(this.userVideo.stream)
      this.props.replaceVideoStreamMutedAudio(this.props.connectionId, !enabled)
    }
  }

  handleMenuScreenShareClick () {
    if (this.canScreenShare) this.switchUserStreamToScreen()
    else this.switchUserStreamToMedia()
  }

  handleMenuWhiteboardClick () {
    const { id } = this.props
    const whiteboardEnabled = !this.props.whiteboardEnabled
    socket.whiteboardEnabled.publish(id, whiteboardEnabled)
    this.props.replaceWhiteboardEnabled(whiteboardEnabled)
  }

  handleMenuMemberRemoveClick () {
    if (this.activeVideo?.memberRemove) {
      const { id } = this.props
      socket.memberRemove.publish(id, this.activeVideo.id)
    }
  }

  handleMenuToggleChange (position: MenuPosition) {
    const isMenuExpanded = this.isMenuExpanded(position)
    const { pullMenuExpanded, pushMenuExpanded } = this.props
    const action = isMenuExpanded ? pullMenuExpanded : pushMenuExpanded
    action(position)
  }

  handleVideoBlockClick (video: VideoState) {
    console.log('Clicked on video...')
    console.log(video.id)
    // FIXME See VideoService.tx about possibly switching to this handling or similar when in "host-centric" mode, otherwise use the TODO handling below
    // this.props.replaceVideoActive(video.id, true)
    // TODO Show / hide bottom title bar with user name / cigar they are smoking or open drawer with details
  }

  handleWhiteboardCanvasClearClick () {
    socket.whiteboardCanvasClear.publish(this.props.id)
  }

  handleWhiteboardDrawingAdd (event: IEvent) {
    const { id } = this.props
    const drawing = event.target as MeetingWhiteboardDrawingState
    this.props.pushWhiteboardDrawing(drawing)
    socket.whiteboardDrawingAdd.publish(id, drawing)
  }

  handleRemoteRaiseHand (meetingId: string) {
    socket.raiseHand.subscribe(meetingId, connectionId => {
      this.props.replaceVideoRaiseHand(connectionId, true)
      setTimeout(() => {
        this.props.replaceVideoRaiseHand(connectionId, false)
      }, Meeting.raiseHandTimeout)
    })
  }

  handleRemoteMemberRemove (meetingId: string) {
    socket.memberRemove.subscribe(meetingId, connectionId => {
      const isUser = this.meetingService.isUserMeeting(connectionId)
      if (isUser) this.peerService.disconnect()
      else {
        this.videoService.pullVideo(connectionId)
        this.meetingRepository.setHostVideoActive()
      }
    })
  }

  handleRemoteDisconnect (meetingId: string) {
    socket.remoteDisconnect.subscribe(meetingId, connectionId => {
      const isHostMeeting = this.meetingService.isHostMeeting(connectionId)
      if (isHostMeeting) this.exitMeeting()
      else this.videoService.pullVideo(connectionId)
    })
  }

  handleRemoteRTPTrackReplacement (meetingId: string) {
    socket.rtpTrackReplaced.subscribe(meetingId, payload => {
      const { connectionId, kind } = payload
      this.props.replaceVideoKind(connectionId, kind)
      this.props.updateVideoRenderId(connectionId)
    })
  }

  handleRemoteConferenceCall (meetingId: string, localStream: MediaStream) {
    socket.conferenceCall.subscribe(meetingId, connectionId => {
      this.notificationService.notifyMember(connectionId)
      if (this.meetingService.canConferenceCall(connectionId)) {
        this.peerService.call(connectionId, localStream, call => {
          this.meetingService.pushCall(call)
          this.handleCallStreaming(call)
        })
      }
    })
  }

  handleRemoteWhiteboardEnabled (meetingId: string) {
    socket.whiteboardEnabled.subscribe(meetingId, whiteboardEnabled => {
      this.props.replaceWhiteboardEnabled(whiteboardEnabled)
    })
  }

  handleRemoteWhiteboardDrawingAdd (meetingId: string) {
    socket.whiteboardDrawingAdd.subscribe(meetingId, drawing => {
      const isDrawingExists = this.whiteboardDrawingService.isExists(drawing)
      if (!isDrawingExists) {
        this.whiteboardDrawingService.parse(drawing, drawings => {
          this.whiteboardDrawingService.add(drawings)
        })
      }
    })
  }

  handleRemoteWhiteboardDrawingSync (localConnectionId: string) {
    socket.whiteboardDrawingSync.subscribe(localConnectionId, whiteboardSync => {
      const { drawings, whiteboardEnabled } = whiteboardSync
      if (whiteboardEnabled) {
        this.props.replaceWhiteboardEnabled(whiteboardEnabled)
      }
      this.props.replaceWhiteboardDrawings(drawings)
      socket.whiteboardDrawingSync.unsubscribe(localConnectionId)
    })
  }

  handleRemoteWhiteboardCanvasClear (meetingId: string) {
    socket.whiteboardCanvasClear.subscribe(meetingId, () => {
      this.whiteboardCanvasService.clear()
      this.props.replaceWhiteboardDrawings([])
    })
  }

  menu (position: MenuPosition) {
    return this.activeVideo &&
      <Menu
        position={position}
        canInviteMember={this.canInviteMember}
        canRaiseHand={this.canRaiseHand}
        canEndMeeting={this.canEndMeeting}
        canVideoMute={this.canVideoMute}
        canAudioMute={this.canAudioMute}
        canScreenShare={this.canScreenShare}
        canWhiteboardEnable={this.canWhiteboardEnable}
        canMemberRemove={this.activeVideo.memberRemove}
        inviteText={this.inviteText}
        isExpanded={this.isMenuExpanded(position)}
        handleRaiseHandClick={this.handleMenuRaiseHandClick}
        handleEndMeetingClick={this.handleMenuEndMeetingClick}
        handleVideoClick={this.handleMenuVideoClick}
        handleAudioClick={this.handleMenuAudioClick}
        handleScreenShareClick={this.handleMenuScreenShareClick}
        handleWhiteboardClick={this.handleMenuWhiteboardClick}
        handleMemberRemoveClick={this.handleMenuMemberRemoveClick}
        handleToggleChange={this.handleMenuToggleChange}
      />
  }

  video (video: VideoState) {
    let size = isMobile ? Math.floor(Math.sqrt(this.userVideos.length)) : Math.ceil(Math.sqrt(this.userVideos.length))
    if (this.userVideos.length === 3) {
      // INFO For some reason, 3 doesn't follow the same formula - it's 2 on both mobile and desktop, though
      size = 2
    }
    return (
      <Col
        // FIXME This should have a max - seems like 4 has 2 cols, almost all up to 10 have 3 cols, and they seem to
        //  typically be the same cols x rows (ie, 7 rows, 7 cols - just like 4 would be 2 rows, 2 cols)
        xs={parseInt(`${12 / size}`)}
        key={video.id}
        style={{ margin: 0, padding: 0 }}
      >
        <VideoBlock
          ratio="full"
          video={video}
          totalCount={this.userVideos.length}
          handleClick={this.handleVideoBlockClick} />
      </Col>
    )
  }

  socketUnsubscribe () {
    const { id } = this.props
    socket.raiseHand.unsubscribe(id)
    socket.conferenceCall.unsubscribe(id)
    socket.rtpTrackReplaced.unsubscribe(id)
    socket.remoteDisconnect.unsubscribe(id)
    socket.memberRemove.unsubscribe(id)
    socket.whiteboardEnabled.unsubscribe(id)
    socket.whiteboardDrawingAdd.unsubscribe(id)
    socket.whiteboardCanvasClear.unsubscribe(id)
  }

  handleDisconnect () {
    this.peerService.onDisconnect(() => {
      const { id, connectionId } = this.props
      socket.remoteDisconnect.publish(id, connectionId)
      this.exitMeeting()
    })
  }

  whiteboardDrawingSync (connectionId: string) {
    const { whiteboardEnabled, whiteboardDrawings: drawings } = this.props
    const whiteboardSync = { drawings, whiteboardEnabled }
    socket.whiteboardDrawingSync.publish(connectionId, whiteboardSync)
  }

  exitMeeting () {
    this.videoService.stopUserVideo()
    this.meetingService.resetMeeting()
    this.socketUnsubscribe()
    this.peerService.disconnect()
    this.authService.resetType()
    this.props.replaceWhiteboardEnabled(false)
    this.props.replaceWhiteboardDrawings([])
    this.props.history.push(routes.home.path)
  }

  handleUserStream (localConnectionId: string, localStream: MediaStream) {
    this.videoService.createMediaVideo(localConnectionId, localStream, localVideo => {
      console.log('Got local video')
      console.log(localVideo)
      this.videoService.pushVideo(localVideo)
    })
  }

  handleCallStreaming (call: MediaConnection) {
    this.peerService.onCalling(call, (remoteConnectionId, remoteStream) => {
      this.videoService.createMediaVideo(remoteConnectionId, remoteStream, remoteVideo => {
        this.videoService.pushVideo(remoteVideo)
      })
    })
  }

  handleReceiveCall (meetingId: string, localStream: MediaStream) {
    this.peerService.onReceiveCall(call => {
      this.meetingService.pushCall(call)
      const { peer: connectionId } = call
      this.peerService.answerCall(call, localStream)
      this.whiteboardDrawingSync(connectionId)
      socket.conferenceCall.publish(meetingId, connectionId)
      this.notificationService.notifyHost(connectionId)
      this.handleCallStreaming(call)
    })
  }

  joinMeeting (meetingId: string, localConnectionId: string, localStream: MediaStream) {
    if (this.meetingService.isMemberMeeting(localConnectionId)) {
      this.peerService.call(meetingId, localStream, call => {
        this.meetingService.pushCall(call)
        this.handleCallStreaming(call)
      })
    }
  }

  handleMeeting () {
    const { history } = this.props
    this.peerService.handleErrors(history)
    this.peerService.onOpen(localConnectionId => {
      this.meetingService.setId(localConnectionId, meetingId => {
        if (meetingId) {
          this.handleRemoteRaiseHand(meetingId)
          this.handleRemoteMemberRemove(meetingId)
          this.handleDisconnect()
          this.mediaService.getStream(history, localStream => {
            this.handleUserStream(localConnectionId, localStream)
            this.handleReceiveCall(meetingId, localStream)
            this.joinMeeting(meetingId, localConnectionId, localStream)
            this.handleRemoteDisconnect(meetingId)
            this.handleRemoteRTPTrackReplacement(meetingId)
            this.handleRemoteConferenceCall(meetingId, localStream)
            this.handleRemoteWhiteboardEnabled(meetingId)
            this.handleRemoteWhiteboardDrawingAdd(meetingId)
            this.handleRemoteWhiteboardDrawingSync(localConnectionId)
            this.handleRemoteWhiteboardCanvasClear(meetingId)
          })
        } else this.exitMeeting()
      })
    })
  }

  // TODO Check the new code - this.peerService seems incorrect here and why is this.state null - use props instead?
  handleEndClick = () => {
    // this.alertService.push('Are you sure to leave the lounge?', () => {
    if (isMobile && this.state && this.state.selectedCigar) {
      // FIXME My only problem with this is that the video and audio should pause at the very least - the user will
      //  likely think they quit, so figure out how to disable both of those (look in Actions.tsx)
      this.setState({ showSmokeSessionModal: true })
    } else if (this.peerService) {
      this.peerService.disconnect()
    } else {
      console.log('Broke...')
    }
    // })
  }

  componentWillUnmount () {
    this.exitMeeting()
  }

  componentDidMount () {
    this.handleMeeting()
    this.updateLink()
  }

  renderCigarItem (cigar: any) {
    return (
      <IonItem button key={cigar.id} onClick={() => {
        this.setState({ hasFocus: false, selectedCigar: cigar })
      }}>
        <IonAvatar slot="start">
          <img src={this.getCigarImage(cigar)} />
        </IonAvatar>
        <IonLabel>
          <h5>{cigar.brand}</h5>
          <h2>{cigar.name}</h2>
          {cigar.vitolas && cigar.vitolas.length > 0 && (
            <div style={{ overflowX: 'scroll' }}>
              {cigar.vitolas.map((vitola: any) => (
                <IonChip key={vitola.id}>
                  <IonLabel>{vitola.formatted_name}</IonLabel>
                </IonChip>
              ))}
            </div>
          )}
        </IonLabel>
      </IonItem>
    )
  }

  // FIXME This isn't rendering the custom rows - just the span each time
  //  See https://codesandbox.io/s/8ln4vnr1v2?from-embed
  renderCigarOptionById = (option: { value: string, label: string }) => {
    const { searchResults } = this.state
    searchResults?.forEach((cigar) => {
      // console.log('Comparing:')
      // console.log(cigar.id)
      // console.log(option.value)
      if (cigar.id === option.value) {
        console.log('Returning rendered row...')
        return this.renderCigarItem(cigar)
      }
    })
    return <div style={{ padding: 10 }} onClick={() => this.setState({ selectedCigar: { id: option.value, full_name: option.label } })}>{option.label}</div>
  };

  render () {
    const { selectedCigar, searchResults, hasFocus, showSmokeSessionModal, shareUrl } = this.state
    return (
      <IonPage>
        <IonContent fullscreen>
          <Page
            flip={false}
            container="full"
            brand={false}>
            <div className={this.className}>
              <div>
                {this.activeVideoBlock}
                {this.userVideos.length === 0 && <VideoSkeleton video={this.activeVideo} id={this.props.id} shareUrl={shareUrl} />}
              </div>
              {/* FIXME Why the need for position absolute and the -3 here? */}
              {this.userVideos.length > 0 && (
                <Row style={{ width: '100vw', padding: 0, margin: 0, position: 'absolute', top: -3 }}>
                  {this.videos}
                </Row>
              )}
              {isMobile && !selectedCigar && (
                <IonToolbar color="primary" style={{ position: 'fixed', top: 0, zIndex: 1000 }}>
                  {/* INFO The timeout allows the row item to be clicked, otherwise the view is removed before the click event is handled */}
                  <IonSearchbar
                    // cancelButtonIcon="arrow-back-outline"
                    onIonChange={e => this.getCigars(e.detail.value!, null)}
                    showCancelButton="focus"
                    placeholder="What are you smoking...?"
                    onFocus={() => { this.setState({ hasFocus: true }) }}
                    onBlur={() => { setTimeout(() => this.setState({ hasFocus: false }), 100) }}
                  />
                </IonToolbar>
              )}
              {isMobile && hasFocus && (
                <div
                  style={{
                    margin: 8,
                    top: 50,
                    position: 'absolute',
                    backgroundColor: '#ffffff',
                    borderRadius: 3,
                    width: 'calc(100% - 16px)',
                    height: 'calc(100vh - 150px)',
                    overflowY: 'scroll',
                    zIndex: 1002
                  }}
                >
                  {/* TODO If not searched yet, something like "Start typing above..." */}
                  {(!searchResults || !searchResults.length) && <div style={{ textAlign: 'center', margin: 20 }}>No results found</div>}
                  {searchResults && searchResults.length > 0 && (
                    <IonList>
                      {searchResults.map((cigar) => this.renderCigarItem(cigar))}
                    </IonList>
                  )}
                </div>
              )}
              {!isMobile && (
                <Actions
                  position="top"
                  activeVideo={this.activeVideo}
                  shareUrl={shareUrl}
                />
              )}
              {!isMobile && !selectedCigar && (
                <div style={{ zIndex: 1002, position: 'absolute', top: 16, right: 30, minWidth: 300 }}>
                  <Select
                    name="selected-cigar"
                    loadOptions={this.getCigars}
                    placeholder="What are you smoking...?"
                    onBlurResetsInput={false}
                    // @ts-ignore
                    components={{ Option: this.renderCigarOptionById }}
                  />
                </div>
              )}
              {selectedCigar && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  minWidth: isMobile ? '100vw' : 300,
                  minHeight: 56,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0 20px',
                  backgroundColor: 'var(--ion-color-primary)',
                  borderRadius: isMobile ? '0' : '0 0 0 3px',
                  color: '#ffffff',
                  zIndex: 1002
                }}>
                  <div style={{ padding: 4, cursor: 'pointer', height: 40, width: 40 }} onClick={() => this.setState({ selectedCigar: null })}>
                    <IonIcon icon={closeOutline} size="large" />
                  </div>
                  <span style={{ flex: 1, marginLeft: 20, marginRight: 20 }}>{selectedCigar.full_name}</span>
                  {!isMobile && (
                    <Button
                      color="secondary"
                      text="Smoke Now"
                      handleClick={() => {
                        console.log('Showing smoke session modal...')
                        this.setState({ showSmokeSessionModal: true })
                      }}
                    />
                  )}
                </div>
              )}
              <Actions
                position="bottom"
                handleEnd={this.handleEndClick}
                activeVideo={this.activeVideo}
                shareUrl={shareUrl}
              />
            </div>
            <SmokeSessionModal
              cigar={selectedCigar}
              show={showSmokeSessionModal}
              onClose={(disconnect: boolean) => {
                this.setState({ showSmokeSessionModal: false })
                if (disconnect) {
                  this.peerService.disconnect()
                }
              }}
            />
          </Page>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(connect(selectors, actions)(Meeting))
