import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Brand from '../Brand/Brand'
import Alert from '../Alert/Alert'
import styles from './Page.module.scss'
import packageJson from '../../../package.json'

interface PageProps {
  flip?: boolean,
  container: 'small' | 'large' | 'full' | 'regular',
  brand?: boolean,
  invert?: boolean,
}

export default class Page extends Component<PageProps> {
  static propTypes = {
    flip: PropTypes.bool.isRequired,
    container: PropTypes.oneOf(['small', 'large', 'full']).isRequired,
    brand: PropTypes.bool.isRequired,
    children: PropTypes.element
  }

  static defaultProps = {
    brand: true
  }

  get className () {
    const flipClass = this.props.flip && styles.flip
    const containerClass = styles[this.props.container]
    return `${styles.page} ${flipClass} ${containerClass}`
  }

  get brand () {
    return this.props.brand &&
        <Brand invert={this.props.flip} />
  }

  get background () {
    if (this.props.brand) {
      return {
        backgroundColor: '#1f1f20',
        backgroundImage: 'url(https://cdn.boxpressd.io/assets/img/background-landing-page.jpg)',
        backgroundSize: 'cover',
        backgroundPositionX: '50%',
        backgroundPositionY: 'bottom',
        backgroundBlendMode: 'color-dodge'
      }
    }
    return { backgroundColor: '#000000' }
  }

  get copyright () {
    const text = `Boxpressd Ltd ${new Date().getFullYear()} - v${packageJson.version}`
    return { __html: `&copy; ${text}` }
  }

  render () {
    return (
      <div className={this.className} role="page">
        <div className={styles.main} style={this.background}>
          {this.brand}
          <div className={styles.content}>
            {this.props.children}
          </div>
        </div>
        {/* <div className={styles.footer}> */}
        {/* <img */}
        {/*  className={styles.background} */}
        {/*  src="/assets/background.svg" */}
        {/*  alt="Background" /> */}
        {/* </div> */}
        <div className={styles.copyright}>
          <a
            className={styles.link}
            href="https://boxpressd.com/legal/privacy">Privacy Policy</a>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={this.copyright} />
        </div>
        <Alert />
      </div>
    )
  }
}
