import React, { useState, useEffect } from 'react'
import branch from 'branch-sdk'
import Rating from 'react-rating'
import { isMobile } from 'mobile-device-detect'
import { Modal } from 'react-bootstrap'
import { useBeforeunload } from 'react-beforeunload'
import Hashids from 'hashids/cjs'
import styles from './SmokeSessionModal.module.scss'
const hashids = new Hashids('', 12)

const env = process.env.REACT_APP_ENV || 'development'
const config = require('../../config/config.json')[env]

// FIXME Should this get initialized sooner? index.ts?
branch.init(config.branch.api_key, {}, (err: any, data: any) => {
  console.log(err, data)
})

interface SmokeSessionProps {
  cigar: any,
  show: boolean,
  onClose: (disconnect?: boolean | any) => void
}

function SmokeSessionModal (props: SmokeSessionProps) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  // const [showMobileAlert, setShowMobileAlert] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sessionRating, setSessionRating] = useState(0)

  useEffect(() => {
    console.log('Show state updated for smoke session modal')
    console.log(props.show ? 'Showing' : 'Hiding')
    setShowModal(props.show)
  }, [props.show])

  // FIXME Why is this still using the default message?? If we do it this way, the prompt to rate can be exclusively on the end action
  // useBeforeunload(() => 'To end your session, please end the call using the Ⓧ icon')
  useBeforeunload((event) => {
    if (props.cigar && !sessionRating) {
      event.preventDefault()
      // @ts-ignore
      event.returnValue = 'To end your session, please end the call using the Ⓧ icon'
    }
  })

  const sendLinkToSMS = () => {
    // TODO Use Branch to send SMS to user with link to open a new smoke session for the selected cigar
    console.log(phoneNumber)

    // TODO Validate that it's a well-formatted phone number
    if (!phoneNumber || phoneNumber.length === 0) {
      setPhoneError(true)
      return
    }

    setPhoneError(false)

    // FIXME Change data here - send user to Smoke Now session editor section of app
    const linkData = {
      campaign: 'content 123',
      channel: 'lounge',
      feature: 'dashboard',
      stage: 'new user',
      tags: ['tag1', 'tag2', 'tag3'],
      alias: '',
      data: {
        custom_bool: true,
        custom_int: Date.now(),
        custom_string: 'hello',
        cigar_id: props.cigar.id,
        $og_title: 'Title',
        $og_description: 'Description',
        $og_image_url: 'http://lorempixel.com/400/400'
      }
    }

    const linkOptions = {
      make_new_link: false // don't create a new deep link if one already exists (e.g. _branch_match_id is in the address bar)
    }

    console.log(linkData)
    console.log(linkOptions)

    // TODO Add Branch.io SDK https://help.branch.io/developers-hub/docs/web-advanced-features#section-create-text-message
    branch.sendSMS(phoneNumber, linkData, linkOptions, (err) => {
      console.log(err)
    })
  }

  if (isMobile) {
    return (
      <Modal show={showModal} size="lg" >
        <Modal.Header>
          <h3 className="modal-title">Rate Your Smoke Session</h3>
          <button type="button" className="close" data-dismiss="modal" onClick={() => props.onClose(true)}>&times;</button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <p>Know your smokes. Rate your smoke session and keep logs of inventory and notes using Boxpressd.</p>
          <div style={{ textAlign: 'center' }}>
            <Rating
              initialRating={sessionRating}
              onChange={(value: number) => {
                setSessionRating(value)
                setTimeout(() => {
                  window.location.href = `https://beta.boxpressd.com/cigars/${hashids.encode(props.cigar.id)}?action=smokenow&itemId=${props.cigar.id}&rating=${value}`
                }, 100)
              }}
              emptySymbol="far fa-star"
              fullSymbol="fas fa-star"
              fractions={2}
              // @ts-ignore
              style={{
                color: 'rgb(214, 194, 144)',
                fontSize: 28
              }}
            />
          </div>
          <img src="https://cdn.boxpressd.io/assets/img/screens/mobile-screens.png" style={{ marginTop: 20, marginBottom: 20 }} />
          <a href="#" onClick={(e) => {
            e.preventDefault()
            props.onClose && props.onClose(true)
          }}>No thanks, just leave lounge</a>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal show={showModal} style={{ maxHeight: '80vh', zIndex: 10000 }} size="lg" >
      <Modal.Header>
        <h3 className="modal-title">Create Smoke Session</h3>
        <button type="button" className="close" data-dismiss="modal" onClick={() => props.onClose(false)}>&times;</button>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="form-group">
          <div className="row">
            <div className="col-7 h-100">
              <div style={{ maxHeight: '30%' }}>
                <h4>Take Boxpressd with you anywhere.</h4>
                <p style={{ fontSize: '16px', color: 'grey', marginTop: 20 }}>
                  Track your inventory and
                  favorite cigars with Boxpressd. Send the link to your mobile
                  device (standard messaging rates apply):
                </p>
                {/* Ask for phone number */}
                <div className="input-group">
                  <input
                    id="sms-phone-number"
                    className={`form-control ${phoneError ? styles.error : ''}`}
                    type="phone"
                    placeholder="123 456 7890"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <div className="input-group-addon">
                    <button
                      style={{ borderRadius: '0 5px 5px 0', backgroundColor: '#d5c290', borderColor: '#d5c290' }}
                      type="button"
                      className="btn btn-brand"
                      id="send-link-button"
                      onClick={() => sendLinkToSMS()}
                    >
                      {'Text me the link'}
                    </button>
                  </div>
                </div>
                {props.cigar && (
                  <a href={`https://beta.boxpressd.com/cigars/${hashids.encode(props.cigar.id)}?action=smokenow&itemId=${props.cigar.id}`}
                    style={{ fontSize: 14, color: 'gray', marginTop: 20, display: 'inline-block' }}
                    rel="noreferrer"
                    target="_blank">Continue to desktop site <i className="feather-chevron-right" />
                  </a>
                )}
                <a href="#"
                  style={{ marginLeft: 50, fontSize: 14, color: 'gray', marginTop: 20, display: 'inline-block' }}
                  target="_blank" onClick={(e) => {
                    e.preventDefault()
                    props.onClose && props.onClose(false)
                  }}
                >
                  {'Not Now'}
                </a>
              </div>

            </div>
            <div className="col-md-5">
              <img src="https://cdn.boxpressd.io/assets/img/screens/mobile-screens.png" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

SmokeSessionModal.defaultProps = {
  show: false
}

export default SmokeSessionModal
