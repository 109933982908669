import { cloneDeep, pull } from 'lodash'
import { ActionsPosition } from '../Actions/Actions'

export interface ActionsToggleState {
  expanded: ActionsPosition[]
}

const actionsToggle: ActionsToggleState = {
  expanded: ['top', 'bottom']
}

export const actions = {
  pushExpanded (expanded: ActionsPosition) {
    return { type: 'PUSH_ACTIONS_TOGGLE_EXPANDED', expanded }
  },
  pullExpanded (expanded: ActionsPosition) {
    return { type: 'PULL_ACTIONS_TOGGLE_EXPANDED', expanded }
  }
}

export function reducers (state = actionsToggle, action: any) {
  switch (action.type) {
    case 'PUSH_ACTIONS_TOGGLE_EXPANDED':
      actionsToggle.expanded.push(action.expanded)
      return cloneDeep(actionsToggle)
    case 'PULL_ACTIONS_TOGGLE_EXPANDED':
      pull(actionsToggle.expanded, action.expanded)
      return cloneDeep(actionsToggle)
    default:
      return state
  }
}

export function selectors (state: { actionsToggle: ActionsToggleState }) {
  return cloneDeep(state.actionsToggle)
}
