import React, { MouseEvent, Component } from 'react'
import { IonButton } from '@ionic/react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

export interface ButtonProps {
    text: any,
    handleClick: (event: MouseEvent) => void,
    color: string,
    shape: 'round' | undefined,
}

export default class Button extends Component<ButtonProps> {
    static propTypes = {
      text: PropTypes.any.isRequired,
      handleClick: PropTypes.func.isRequired,
      color: PropTypes.string,
      shape: PropTypes.string
    }

    static defaultProps = {
      color: 'primary',
      shape: undefined
    }

    render () {
      return (
        <div className={styles.button}>
          <IonButton
            shape={this.props.shape}
            expand="block"
            color={this.props.color}
            onClick={this.props.handleClick}>
            {this.props.text}
          </IonButton>
        </div>
      )
    }
}
