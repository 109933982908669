import { actions as meetingActions } from '../Meeting/Meeting.state'
import { AnyAction } from 'redux'

export interface MeetingJoinActions {
  replaceMeetingId: (id: string) => AnyAction
}

export const actions: MeetingJoinActions = {
  replaceMeetingId (id: string) {
    return meetingActions.replaceId(id)
  }
}
