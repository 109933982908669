import React, { MouseEvent, Component } from 'react'
import { IonIcon } from '@ionic/react'
import PropTypes from 'prop-types'
import {
  closeCircleOutline,
  easelOutline,
  handRightOutline,
  laptopOutline,
  micOutline,
  micOffOutline,
  personAddOutline,
  personRemoveOutline,
  videocamOutline,
  videocamOffOutline
} from 'ionicons/icons'
import styles from './Action.module.scss'

type Types = 'invite' | 'raiseHand' | 'end' | 'video' | 'audio' | 'screenShare' | 'whiteboard' | 'memberRemove'

export interface ActionProps {
  type: Types,
  active: boolean,
  handleClick: (event: MouseEvent) => void
}

export default class Action extends Component<ActionProps> {
  static propTypes = {
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired
  }

  get className () {
    const activeClass = this.props.active ? styles.active : ''
    return `${styles.action} ${activeClass}`
  }

  get icon () {
    switch (this.props.type) {
      case 'invite':
        return personAddOutline
      case 'raiseHand':
        return handRightOutline
      case 'end':
        return closeCircleOutline
      case 'video':
        return this.props.active ? videocamOffOutline : videocamOutline
      case 'audio':
        return this.props.active ? micOffOutline : micOutline
      case 'screenShare':
        return laptopOutline
      case 'whiteboard':
        return easelOutline
      case 'memberRemove':
        return personRemoveOutline
    }
  }

  // FIXME These should have tooltips that say what the action does, not just the name of the icon
  render () {
    return (
      <div
        className={this.className}
        role={this.props.type}
        onClick={this.props.handleClick}>
        <IonIcon icon={this.icon} />
      </div>
    )
  }
}
