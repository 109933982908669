import React from 'react'
import axios from 'axios'
import firebase from 'firebase'
import ReactDOM from 'react-dom'
import ServiceWorkerService from './services/ServiceWorkerService'
import App from './components/App/App'
import firebaseApp from './config/firebase.config'
import './scss/overrides.scss'

import AppStorage from './components/App/App.storage'
const appStorage = new AppStorage()

appStorage.getAccessToken((token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
})

axios.interceptors.response.use((response) => response, async (error) => {
  console.log('=== Axios Error ===')
  console.log(error.config)
  console.log(error)

  if (error.response && error.response.status === 403) {
    if (error.response.data.code && error.response.data.code === 'auth/id-token-expired') {
      console.log('The token is expired.')
      if (firebaseApp.auth().currentUser) {
        const tokenId = await firebaseApp.auth().currentUser!.getIdToken(true)
        // window.localStorage.setItem('boxpressd_token_id', tokenId || '')
        await appStorage.setAccessToken(tokenId, () => {})
        console.log('Got new token in interceptor')
        console.log(tokenId)
        // eslint-disable-next-line no-param-reassign
        error.config.headers.Authorization = `Bearer ${tokenId}`
        // eslint-disable-next-line no-param-reassign
        error.config.baseURL = undefined
        // return axios.request(error.config) // FIXME This is what causes a loop - it should only retry so many times here
      }
    }
    const urlParams = new URLSearchParams(window.location.search)
    const userToken = urlParams.get('token')
    axios.defaults.headers.common.Authorization = `Bearer ${userToken}`
    if (userToken) {
      console.log('Trying call with token from query param:')
      console.log(userToken)
      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${userToken}`
      // eslint-disable-next-line no-param-reassign
      error.config.baseURL = undefined
      return axios.request(error.config)
    }
  }
  return Promise.reject(error)
})

firebaseApp.auth().onIdTokenChanged((user: firebase.User | null) => {
  console.log('Token updated')
  if (user) {
    user.getIdToken().then(async (tokenId: string) => {
      console.log('Storing token...')
      axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`
      // window.localStorage.setItem('boxpressd_token_id', tokenId || '')
      await appStorage.setAccessToken(tokenId, () => {})
    })
  }
})

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

ServiceWorkerService.registerWorker()
