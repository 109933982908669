import { IonAvatar, IonCol, IonIcon, IonItem, IonLabel, isPlatform } from '@ionic/react'
import { videocamOutline, personAddOutline, copy } from 'ionicons/icons'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'mobile-device-detect'
import AlertService from '../../services/AlertService'
import Skeleton from '../Skeleton/Skeleton'
import styles from './VideoSkeleton.module.scss'
import Button from '../Button/Button'
import AppStorage from '../App/App.storage'
import { SocialSharing } from '@ionic-native/social-sharing'
import copyToClipboard from 'copy-to-clipboard'

let windowNavigator: any

windowNavigator = window.navigator

interface VideoSkeletonProps {
  video: any,
  id: string
  shareUrl: string,
}

interface VideoSkeletonState {
  loungeName: string,
  userAvatar: string
}

export default class VideoSkeleton extends Component<VideoSkeletonProps, VideoSkeletonState> {
  protected alertService: AlertService

  static propTypes = {
    video: PropTypes.any,
    id: PropTypes.string
  }

  constructor (props: VideoSkeletonProps) {
    super(props)
    this.alertService = new AlertService()
    this.state = {
      loungeName: '',
      userAvatar: 'https://cdn.boxpressd.io/placeholder/200x200/default_avatar.png'
    }
  }

  componentDidMount (): void {
    const appStorage = new AppStorage()
    appStorage.getUser((user) => {
      if (user) {
        // FIXME Way to get the lounge name from the host response?
        this.setState({ loungeName: `${user.first_name}'s Lounge`, userAvatar: user.image_url || this.state.userAvatar })
      }
    })
  }

  get skeletonHeight () {
    return isPlatform('mobile') ? 'calc(100vw / (16/9))' : '100vh'
  }

  copyLink = () => {
    var textField = document.createElement('textarea')
    textField.innerText = this.props.shareUrl
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    AlertService.push('Link copied')
  }

  get inviteText () {
    return `Hi there,\nYou can join the Virtual Lounge from ${this.props.shareUrl}`
  }

  handleInviteClick = () => {
    console.log('Clicked invite button')
    if (isPlatform('hybrid')) {
      SocialSharing.share(this.inviteText)
    } else if (windowNavigator.share) {
      windowNavigator.share({
        title: 'Invite users to join',
        text: this.inviteText,
        url: this.props.shareUrl
      })
    } else {
      copyToClipboard(this.inviteText)
      AlertService.push('Invitation copied.')
    }
  }

  render () {
    if (!this.props.video) {
      return (
        <IonCol size="12">
          <div className={styles.videoSkeleton}>
            <Skeleton
              width="100%"
              height="0"
              paddingBottom={this.skeletonHeight} />
            {/* TODO Some kind of prompt letting them know they need to enable video if not enabled, otherwise default to what's already here? */}
            <IonIcon
              className={styles.icon}
              icon={videocamOutline} />
          </div>
        </IonCol>
      )
    }
    return (
      <div style={{
        zIndex: 1000,
        textAlign: 'center',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: isMobile ? 'calc(100vh - 320px)' : '100vh',
        // INFO These two lines fix the issue where the Invite People green button cannot be clicked - another way to fix that issue?
        position: 'absolute',
        left: 'calc(50% - 125px)'
      }}>
        <div style={{ margin: 'auto', maxWidth: 250 }}>
          <IonAvatar style={{ margin: 'auto' }}>
            <img src={this.state.userAvatar} />
          </IonAvatar>
          <h3>{this.state.loungeName}</h3>
          <p>Wait for your friends to join or invite them so they know you&apos;re here.</p>
          {/* FIXME The only issue here is that this button is not clickable due to the zIndex */}
          <Button
            color="success"
            text={<span><IonIcon icon={personAddOutline} /> Invite People</span>}
            handleClick={this.handleInviteClick}
          />
          <IonItem button onClick={this.copyLink} style={{ borderRadius: 3, zIndex: 1000 }}>
            <IonLabel>
              <h3>{this.props.shareUrl}</h3>
            </IonLabel>
            <IonIcon icon={copy} slot="end" />
          </IonItem>
        </div>
      </div>
    )
  }
}
