import { IonIcon } from '@ionic/react'
import { chevronDown, chevronUp } from 'ionicons/icons'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { includes } from 'lodash'
import store from '../../store'
import { actions, ActionsToggleState, selectors } from './ActionsToggle.state'
import { ActionsPosition } from '../Actions/Actions'
import styles from './ActionsToggle.module.scss'

type OnChangeCallback = (expanded: boolean) => void

interface ActionsToggleProps extends ActionsToggleState {
  position: ActionsPosition,
  onChange: OnChangeCallback
}

class ActionsToggle extends Component<ActionsToggleProps> {
  constructor (props: ActionsToggleProps) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  isPosition (position: ActionsPosition) {
    return this.props.position === position
  }

  get icon () {
    return this.isPosition('top')
      ? this.isExpanded ? chevronUp : chevronDown
      : this.isPosition('bottom')
        ? this.isExpanded ? chevronDown : chevronUp
        : undefined
  }

  get className () {
    const positionClass = styles[this.props.position]
    return `${styles.actionsToggle} ${positionClass}`
  }

  get isExpanded () {
    const { expanded, position } = this.props
    return includes(expanded, position)
  }

  handleClick () {
    this.isExpanded
      ? store.dispatch(actions.pullExpanded(this.props.position))
      : store.dispatch(actions.pushExpanded(this.props.position))
    setTimeout(() => this.props.onChange(this.isExpanded), 0)
  }

  render () {
    return (
      <div className={this.className}>
        <div className={styles.button} onClick={this.handleClick}>
          <IonIcon icon={this.icon} className={styles.icon} />
        </div>
      </div>
    )
  }
}

export default connect(selectors, actions)(ActionsToggle)
