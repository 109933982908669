import { IonContent, IonPage } from '@ionic/react'
import React, { Component, Fragment } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import routes from '../../routes'
import Button from '../../components/Button/Button'
import Page from '../../components/Page/Page'

interface HomeProps extends RouteComponentProps {}

class Home extends Component<HomeProps> {
  constructor (props: HomeProps) {
    super(props)
    this.handleStartClick = this.handleStartClick.bind(this)
    this.handleJoinClick = this.handleJoinClick.bind(this)
  }

  handleStartClick () {
    this.props.history.push(routes.start.path)
  }

  handleJoinClick () {
    this.props.history.push(routes.join.path)
  }

  render () {
    return (
      <IonPage>
        <IonContent fullscreen>
          <Page flip={false} container="small">
            <Fragment>
              {/* style={{ background: 'url(https://cdn.boxpressd.io/assets/img/background-landing-page.jpg)' }} */}
              <Button
                color="success"
                text="Create Lounge"
                handleClick={this.handleStartClick} />
              <Button
                color="success"
                text="Join a Lounge"
                handleClick={this.handleJoinClick} />
            </Fragment>
          </Page>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(Home)
