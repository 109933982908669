import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import { reducers as app } from './components/App/App.state'
import { reducers as alert } from './components/Alert/Alert.state'
import { reducers as meeting } from './pages/Meeting/Meeting.state'
import { reducers as hosts } from './pages/Hosts/Hosts.state'
import { reducers as actionsToggle } from './components/ActionsToggle/ActionsToggle.state'
import { reducers as actionsState } from './components/Actions/Actions.state'
import { reducers as whiteboard } from './components/Whiteboard/Whiteboard.state'

const reducers = combineReducers({
  app,
  alert,
  meeting,
  hosts,
  actionsToggle,
  actionsState,
  whiteboard
})

export default createStore(reducers, composeWithDevTools(applyMiddleware()))
