import StorageService from '../../services/StorageService'

interface User {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  full_name: string,
  image_url: string
}

interface Key {
  pwaInstallPrompted: string
  accessToken: string
  user: string
}

type UserGetCallback = (user: User | null) => void
type AccessTokenGetCallback = (accessToken: string | null) => void
type PwaPromptCheckCallback = (pwaPrompted: boolean) => void

export default class AppStorage {
  private key: Key
  private storageService: StorageService

  constructor () {
    this.key = {
      pwaInstallPrompted: 'pwaInstallPrompted',
      accessToken: 'accessToken',
      user: 'user'
    }
    this.storageService = new StorageService()
  }

  async checkPwaPrompted (callback: PwaPromptCheckCallback) {
    const pwaPromptedValue = await this.storageService
      .get(this.key.pwaInstallPrompted)
    const pwaPrompted = pwaPromptedValue
      ? Boolean(JSON.parse(pwaPromptedValue))
      : false
    callback(pwaPrompted)
  }

  async setPwaInstallPrompted (callback: () => void) {
    const pwaPrompted = JSON.stringify(true)
    await this.storageService.set(this.key.pwaInstallPrompted, pwaPrompted)
    callback()
  }

  async getAccessToken (callback: AccessTokenGetCallback) {
    const accessToken = await this.storageService.get(this.key.accessToken)
    callback(accessToken)
  }

  async setAccessToken (accessToken: string, callback: () => void) {
    await this.storageService.set(this.key.accessToken, accessToken)
    callback()
  }

  async removeAccessToken (callback: () => void) {
    await this.storageService.remove(this.key.accessToken)
    callback()
  }

  async getUser (callback: UserGetCallback) {
    const user = await this.storageService.get(this.key.user)
    callback(JSON.parse(user!))
  }

  async setUser (user: User, callback: () => void) {
    await this.storageService.set(this.key.user, JSON.stringify(user))
    callback()
  }

  async removeUser (callback: () => void) {
    await this.storageService.remove(this.key.user)
    callback()
  }
}
