import { isPlatform } from '@ionic/react'
import React, { Component } from 'react'
import { isMobile } from 'mobile-device-detect'
import { VideoState } from '../../pages/Meeting/Meeting.state'
import Video from '../Video/Video'
import RaiseHand from '../RaiseHand/RaiseHand'
import styles from './VideoBlock.module.scss'
import Draggable from 'react-draggable';

export type VideoBlockHandleClick = (video: VideoState) => void

export interface VideoBlockProps {
  ratio: 'full' | 'square' | 'default'
  video: VideoState
  handleClick: VideoBlockHandleClick
  totalCount: number
  draggable: boolean
}

export default class VideoBlock extends Component<VideoBlockProps> {
  static defaultProps = {
    totalCount: 1,
    draggable: false
  }

  state = {
    activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
  };

  constructor (props: VideoBlockProps) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  get className () {
    // FIXME This works for all but 3 - for whatever reason, it's the only one that should round up on desktop and down on mobile
    let rows = isMobile ? Math.ceil(Math.sqrt((this.props.totalCount))) : Math.floor(Math.sqrt((this.props.totalCount)))
    if (this.props.totalCount === 3) {
      rows = 2
    }
    let heightClass = ''
    if (rows >= 4) {
      heightClass = styles.quarter_height
    } else if (rows >= 3) {
      heightClass = styles.third_height
    } else if (rows >= 2) {
      heightClass = styles.half_height
    }
    const ratioClass = styles[this.props.ratio]
    const mobileClass = isPlatform('mobile') && styles.mobile
    return `${styles.videoBlock} ${ratioClass} ${mobileClass} ${heightClass}`
  }

  handleClick () {
    console.log('Clicked on video...')
    this.props.handleClick(this.props.video)
  }

  // @ts-ignore
  handleDrag = (e, ui) => {
    console.debug(e)
    console.debug(ui)
    const { x, y } = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  };

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  render () {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    return (
      <div
        className={this.className}
        onClick={this.handleClick}
      >
        <div className={styles.content}>
          {/* FIXME The bounds don't work on desktop - all sides but the top work */}
          {this.props.draggable && (
            <Draggable bounds="body" onDrag={this.handleDrag} {...dragHandlers}>
              {/* TODO Calculate bottom based on position of bottom bar and whether it's open */}
              <div style={{ position: 'fixed', bottom: 80, right: 20, zIndex: 10000 }}>
                <Video video={this.props.video} />
              </div>
            </Draggable>
          )}
          {!this.props.draggable && <Video video={this.props.video} />}
          <RaiseHand visibility={this.props.video.raiseHand} />
        </div>
      </div>
    )
  }
}
