import React from 'react'
import axios from 'axios'
import packageJson from '../package.json'
// @ts-ignore
global.appVersion = packageJson.version

// https://dev.to/flexdinesh/cache-busting-a-react-app-22lk

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

interface CacheProps {
    children: any,
}

interface CacheState {
    showToast: boolean,
    loading: boolean,
    isLatestVersion: boolean,
    refreshCacheAndReload: () => void
}

class CacheBuster extends React.Component<CacheProps, CacheState> {
  constructor (props: any) {
    super(props)
    this.state = {
      showToast: false,
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            for (const name of names) { caches.delete(name) }
          })

          // FIXME I think this was causing the white screen and state update issue - almost positive it's in this file if not
          // this.setState({
          //   showToast: true,
          // });
        }
      }
    }
  }

  componentDidMount () {
    axios.get('/meta.json')
      .then((res) => {
        const meta = res.data
        const latestVersion = meta.version
        // @ts-ignore
        const currentVersion = global.appVersion

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`)
          this.setState({ loading: false, isLatestVersion: false })
        } else {
          console.log(`You 
          already have the latest version - ${latestVersion}. No cache refresh needed.`)
          this.setState({ loading: false, isLatestVersion: true })
        }
      }).catch((err) => {
        console.log(err) // Most likely, 404 error
        this.setState({ loading: false, isLatestVersion: false })
      })
  }

  render () {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state
    return (
      <div>
        { this.props.children({ loading, isLatestVersion, refreshCacheAndReload }) }
        {/* FIXME I never had this working in the main app - would be nice to alert the user of a new version and let them refresh */}
        {/* <Snackbar */}
        {/*  open={this.state.showToast} */}
        {/*  onClose={() => this.setState({ */}
        {/*    showToast: false */}
        {/*  })} */}
        {/*  autoHideDuration={12000} */}
        {/*  ContentProps={{ */}
        {/*    'aria-describedby': 'snackbar-fab-message-id', */}
        {/*    role: 'alertdialog' */}
        {/*  }} */}
        {/*  message="New App Version Available" */}
        {/*  action={ */}
        {/*    <Button */}
        {/*      color="inherit" */}
        {/*      size="small" */}
        {/*      onClick={() => { */}
        {/*        // delete browser cache and hard reload */}
        {/*        window.location.reload(true) */}
        {/*      }} */}
        {/*    > */}
        {/*      Reload */}
        {/*    </Button> */}
        {/*  } */}
        {/* /> */}
      </div>
    )
  }
}

export default CacheBuster
