import { forEach } from 'lodash'
import React, { Component, Fragment } from 'react'
import { isPlatform } from '@ionic/react'
import { SocialSharing } from '@ionic-native/social-sharing'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import copyToClipboard from 'copy-to-clipboard'
import store from '../../store'
import meetingSocket from '../../pages/Meeting/Meeting.socket'
import MediaService from '../../services/MediaService'
import VideoService from '../../services/VideoService'
import AlertService from '../../services/AlertService'
import ScreenCaptureService from '../../services/ScreenCaptureService'
import MeetingService from '../../services/MeetingService'
import StreamTrackService from '../../services/StreamTrackService'
import RTPSenderService from '../../services/RTPSenderService'
import MeetingRepository from '../../repositories/MeetingRepository'
import AuthService from '../../services/AuthService'
import { actions, selectors, ActionsState, ActionsActions } from './Actions.state'
import { selectors as meetingSelectors, VideoState } from '../../pages/Meeting/Meeting.state'
import Action from '../Action/Action'
import ActionsToggle from '../ActionsToggle/ActionsToggle'
import styles from './Actions.module.scss'

const env = process.env.REACT_APP_ENV || 'development'
const config = require('../../config/config.json')[env]

export type ActionsPosition = 'top' | 'bottom'

let windowNavigator: any

windowNavigator = window.navigator

interface ActionsProps extends ActionsState, ActionsActions, RouteComponentProps {
  position: ActionsPosition
  activeVideo: VideoState | undefined
  handleEnd?: () => void
  shareUrl: string,
}

interface ActionsLocalState {
  buttonsVisibility: boolean,
  raiseHand: boolean,
}

class Actions extends Component<ActionsProps, ActionsLocalState> {
  protected mediaService: MediaService

  protected videoService: VideoService

  protected alertService: AlertService

  protected screenCaptureSerivce: ScreenCaptureService

  protected meetingService: MeetingService

  private streamTrackService: StreamTrackService

  private rtpSenderService: RTPSenderService

  private meetingRepository: MeetingRepository

  private authService: AuthService

  constructor (props: ActionsProps) {
    super(props)
    this.state = {
      buttonsVisibility: true,
      raiseHand: true,
    }
    this.mediaService = new MediaService()
    this.videoService = new VideoService()
    this.alertService = new AlertService()
    this.screenCaptureSerivce = new ScreenCaptureService()
    this.meetingService = new MeetingService()
    this.streamTrackService = new StreamTrackService()
    this.rtpSenderService = new RTPSenderService()
    this.meetingRepository = new MeetingRepository()
    this.authService = new AuthService()
    this.handleInviteClick = this.handleInviteClick.bind(this)
    this.handleRaiseHandClick = this.handleRaiseHandClick.bind(this)
    this.handleEndClick = this.handleEndClick.bind(this)
    this.handleVideoClick = this.handleVideoClick.bind(this)
    this.handleAudioClick = this.handleAudioClick.bind(this)
    this.handleScreenShareClick = this.handleScreenShareClick.bind(this)
    this.handleWhiteboardClick = this.handleWhiteboardClick.bind(this)
    this.handleActionsToggleChange = this.handleActionsToggleChange.bind(this)
    this.handleMemberRemoveClick = this.handleMemberRemoveClick.bind(this)
  }

  private handleNativeStreamStop () {
    const screenStream = this.meetingService.getScreenStream()
    const tracks = screenStream.getTracks()
    forEach(tracks, track => {
      track.onended = () => {
        this.switchUserStreamToMedia()
      }
    })
  }

  private switchUserStreamToMedia () {
    this.mediaService.getStream(this.props.history, () => {
      const mediaStream = this.meetingService.getMediaStream()
      this.videoService.replaceUserStream(mediaStream, 'media')
      this.rtpSenderService.replaceStream(mediaStream)
      const screenStream = this.meetingService.getScreenStream()
      this.streamTrackService.stop(screenStream)
    })
  }

  private switchUserStreamToScreen () {
    this.screenCaptureSerivce.getStream(() => {
      const screenStream = this.meetingService.getScreenStream()
      this.videoService.replaceUserStream(screenStream, 'screen')
      this.rtpSenderService.replaceStream(screenStream)
      this.handleNativeStreamStop()
      const mediaStream = this.meetingService.getMediaStream()
      this.streamTrackService.stop(mediaStream)
    })
  }

  handleActionsToggleChange (expanded: boolean) {
    this.setState({ buttonsVisibility: expanded })
  }

  get meetingId () {
    const { id } = meetingSelectors(store.getState())
    return id
  }

  get canInvite () {
    return Boolean(this.meetingId)
  }

  get canEnd () {
    return Boolean(this.meetingId)
  }

  handleInviteClick () {
    const inviteText = `Hi there,\nYou can join the Virtual Lounge from ${this.props.shareUrl}`
    if (this.canInvite) {
      if (isPlatform('hybrid')) {
        SocialSharing.share(inviteText)
      } else if (windowNavigator.share) {
        windowNavigator.share({
          title: 'Invite users to join',
          text: inviteText,
          url: this.props.shareUrl
        })
      } else {
        copyToClipboard(inviteText)
        AlertService.push('Invitation copied.')
      }
    }
  }

  handleRaiseHandClick () {
    const video = this.meetingRepository.getUserVideo()
    if (video && video.raiseHand) return
    const { id: meetingId, connectionId } = meetingSelectors(store.getState())
    meetingSocket.raiseHand.publish(meetingId, connectionId)
    this.setState({ raiseHand: false })
    setTimeout(() => this.setState({ raiseHand: true }), 5000)
  }

  handleEndClick () {
    if (this.canEnd && this.props.handleEnd) this.props.handleEnd()
  }

  handleVideoClick () {
    const video = this.meetingRepository.getUserVideo()
    if (video) this.mediaService.toggleVideoMute(video.stream)
    this.props.replaceVideoEnabled(!this.props.videoEnabled)
  }

  handleAudioClick () {
    const video = this.meetingRepository.getUserVideo()
    if (video) this.mediaService.toggleAudioMute(video.stream)
    this.props.replaceAudioEnabled(!this.props.audioEnabled)
  }

  handleScreenShareClick () {
    if (this.props.screenShared) this.switchUserStreamToMedia()
    else this.switchUserStreamToScreen()
  }

  handleWhiteboardClick () {
    const whiteboardEnabled = !this.props.whiteboardEnabled
    const { id: meetingId } = meetingSelectors(store.getState())
    meetingSocket.whiteboardEnabled.publish(meetingId, whiteboardEnabled)
    this.props.replaceWhiteboardEnabled(whiteboardEnabled)
  }

  handleMemberRemoveClick () {
    if (this.canMemberRemove) {
      const meetignId = this.meetingService.getMeetingId()
      const { activeVideo } = this.props
      if (activeVideo) {
        meetingSocket.memberRemove.publish(meetignId, activeVideo.id)
      }
    }
  }

  isPosition (position: ActionsPosition) {
    return (this.props.position === position)
  }

  actionsToggle (postion: ActionsPosition) {
    return this.isPosition(postion) &&
      <ActionsToggle
        position={this.props.position}
        onChange={this.handleActionsToggleChange} />
  }

  get canMemberRemove (): boolean {
    const { activeVideo } = this.props
    return Boolean(activeVideo && activeVideo.memberRemove)
  }

  get memberRemoveVisibility () {
    return this.authService.isHostAuth()
  }

  get screenShareVisibility () {
    return !isPlatform('mobile')
  }

  get screenShare () {
    return this.screenShareVisibility &&
      <div className={styles.action}>
        <Action
          type="screenShare"
          active={this.props.screenShared}
          handleClick={this.handleScreenShareClick} />
      </div>
  }

  get memberRemove () {
    return this.memberRemoveVisibility &&
      <div className={styles.action}>
        <Action
          type="memberRemove"
          active={this.canMemberRemove}
          handleClick={this.handleMemberRemoveClick} />
      </div>
  }

  get className () {
    const positionClass = styles[this.props.position]
    const mobileClass = isPlatform('mobile') && styles.mobile
    return `${styles.actions} ${positionClass} ${mobileClass}`
  }

  get bottomButtons () {
    return (
      <Fragment>
        <div className={styles.action}>
          <Action
            type="invite"
            active={this.canInvite}
            handleClick={this.handleInviteClick} />
        </div>
        <div className={styles.action}>
          <Action
            type="raiseHand"
            active={this.state.raiseHand}
            handleClick={this.handleRaiseHandClick} />
        </div>
        <div className={styles.action}>
          <Action
            type="end"
            active={this.canEnd}
            handleClick={this.handleEndClick} />
        </div>
        <div className={styles.action}>
          <Action
            type="video"
            active={this.props.videoEnabled}
            handleClick={this.handleVideoClick} />
        </div>
        <div className={styles.action}>
          <Action
            type="audio"
            active={this.props.audioEnabled}
            handleClick={this.handleAudioClick} />
        </div>
      </Fragment>
    )
  }

  get topButtons () {
    return (
      <Fragment>
        {this.screenShare}
        <div className={styles.action}>
          <Action
            type="whiteboard"
            active={this.props.whiteboardEnabled}
            handleClick={this.handleWhiteboardClick} />
        </div>
        {this.memberRemove}
      </Fragment>
    )
  }

  get buttons () {
    return this.isPosition('bottom')
      ? this.bottomButtons
      : this.isPosition('top')
        ? this.topButtons
        : null
  }

  get buttonGroup () {
    return this.state.buttonsVisibility &&
      <div className={styles.buttons}>
        {this.buttons}
      </div>
  }

  render () {
    return (
      <div className={this.className}>
        {this.actionsToggle('bottom')}
        {this.buttonGroup}
        {this.actionsToggle('top')}
      </div>
    )
  }
}

export default withRouter(connect(selectors, actions)(Actions))
