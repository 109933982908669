import { AnyAction } from 'redux'
import { cloneDeep } from 'lodash'

export type AuthType = 'admin' | 'host' | null

interface AppState {
  auth: {
    type: AuthType
    user: object | null
  }
}

interface AppActions {
  replaceAuthType (type: AuthType): AnyAction,
  updateAuth (user: object, tokenId: string, callback: () => void): AnyAction,
}

const app: AppState = {
  auth: {
    type: null,
    user: null
  }
}

export const actions: AppActions = {
  replaceAuthType (authType) {
    return { type: 'REPLACE_APP_AUTH_TYPE', authType }
  },
  updateAuth (user: object, tokenId: string, callback: () => void): AnyAction {
    return { type: 'AUTH_USER', user, tokenId, callback }
  }
}

export function reducers (state = app, action: any) {
  switch (action.type) {
    case 'REPLACE_APP_AUTH_TYPE':
      app.auth.type = action.authType
      return cloneDeep(app)
    case 'AUTH_USER':
      app.auth.user = action.user
      return cloneDeep(app)
    default:
      return state
  }
}

export function selectors (state: { app: AppState }) {
  return cloneDeep(state.app)
}
