import { Animation, createAnimation } from '@ionic/react'

// FIXME I think these guys added their own transition somehow and it's overriding the defaults. This app should use
//  IonRouterOutlet in Provider for page animations
// https://morioh.com/p/86fb658f09e3
export const transitionAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  if (opts.direction === 'forward') {
    return createAnimation()
      // .addElement(baseEl.querySelector('ion-backdrop')!)
      .addElement(opts.enteringEl)
      .fromTo('top', '100%', '0')
  } else {
    return createAnimation()
      .addElement(opts.leavingEl)
      .fromTo('top', '0', '100%')
  }
}
