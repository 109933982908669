import firebase from 'firebase'

// INFO NODE_ENV cannot be overridden when using react-scripts build and custom env vars must start with REACT_APP_
const env = process.env.REACT_APP_ENV || 'development'
const dev = env === 'development'

let config = {
  apiKey: 'AIzaSyDM5HrPL4RcegKWSwrhB8InkphDvhIJEG4',
  authDomain: 'sigaro-dev.firebaseapp.com',
  databaseURL: 'https://sigaro-dev.firebaseio.com',
  projectId: 'sigaro-dev',
  storageBucket: 'sigaro-dev.appspot.com',
  messagingSenderId: '924353704958',
  appId: '1:924353704958:web:857cbace205f3264'
}

if (dev) {
  console.log('Using dev settings')
} else {
  console.log('Using prod settings')
  config = {
    apiKey: 'AIzaSyD23oTCFdNoSGDkVwmUkxQYHTL_TauIAEM', // FIXME Need to restrict this key at https://console.developers.google.com/apis/credentials?project=sigaro-app
    authDomain: 'auth.boxpressd.com',
    databaseURL: 'https://sigaro-app.firebaseio.com',
    projectId: 'sigaro-app',
    storageBucket: 'sigaro-app.appspot.com',
    messagingSenderId: '506026949744',
    appId: '1:506026949744:web:8993e649a884f845'
  }
}

const firebaseApp = firebase.initializeApp(config)

export default firebaseApp
