import { AnyAction } from 'redux'
import { cloneDeep } from 'lodash'

export interface ActionsState {
  videoEnabled: boolean,
  audioEnabled: boolean,
  screenShared: boolean,
  whiteboardEnabled: boolean
}

export interface ActionsActions {
  replaceVideoEnabled: (videoEnabled: boolean) => AnyAction,
  replaceAudioEnabled: (audioEnabled: boolean) => AnyAction,
  replaceScreenShared: (screenShared: boolean) => AnyAction,
  replaceWhiteboardEnabled: (whiteboardEnabled: boolean) => AnyAction
}

const actionsState: ActionsState = {
  videoEnabled: false,
  audioEnabled: false,
  screenShared: false,
  whiteboardEnabled: false
}

export const actions: ActionsActions = {
  replaceVideoEnabled (videoEnabled: boolean) {
    return { type: 'REPLACE_ACTIONS_VIDEO_ENABLED', videoEnabled }
  },
  replaceAudioEnabled (audioEnabled: boolean) {
    return { type: 'REPLACE_ACTIONS_AUDIO_ENABLED', audioEnabled }
  },
  replaceScreenShared (screenShared: boolean) {
    return { type: 'REPLACE_ACTIONS_SCREEN_SHARED', screenShared }
  },
  replaceWhiteboardEnabled (whiteboardEnabled: boolean) {
    return { type: 'REPLACE_ACTIONS_WHITEBOARD_ENABLED', whiteboardEnabled }
  }
}

export function reducers (state = actionsState, action: any) {
  switch (action.type) {
    case 'REPLACE_ACTIONS_VIDEO_ENABLED':
      actionsState.videoEnabled = action.videoEnabled
      return cloneDeep(actionsState)
    case 'REPLACE_ACTIONS_AUDIO_ENABLED':
      actionsState.audioEnabled = action.audioEnabled
      return cloneDeep(actionsState)
    case 'REPLACE_ACTIONS_SCREEN_SHARED':
      actionsState.screenShared = action.screenShared
      return cloneDeep(actionsState)
    case 'REPLACE_ACTIONS_WHITEBOARD_ENABLED':
      actionsState.whiteboardEnabled = action.whiteboardEnabled
      return cloneDeep(actionsState)
    default:
      return state
  }
}

export function selectors (state: { actionsState: ActionsState }) {
  return cloneDeep(state.actionsState)
}
